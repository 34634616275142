// --- PRIORITY MODULES ---

// Import ServiceWorker file
// import '../webworker/serviceWorker';

// Polyfills
import './polyfills';
// Lazysizes
import './modules/lazysizes.js';
// Global
// import 'jquery';

// --- END-PRIORITY MODULES ---


// --- Deferred modules ---

// jQuery is top-priority (most modules depend on it to be ready)
import('jquery').then(async ({ 'default': $ }) => {

	// Initialize the config first
	// NOTE: very important that we await the config load because there are multiple places in the modules that require the config in module load
	await import('./config/_init-config.js');
	// Import all priority modules (tag-manager)

	// import('./config/_init-priority');

	// (DirtyFix) Make sure everything is loaded after document is ready
	$(async () => {

		// Now we can import all modules
		await import('./config/_init-modules.js');

		// Import the init file and trigger the ready event
		// import('./mpro/init');
		// Just run the init function
		mpro.init({ now: true });
	});
});

// --- END-Deferred modules ---
